import { getLocationDistance } from '../helpers/locationHelper';
import * as Notifications from 'expo-notifications';
import { Loot8Token__factory } from '../typechain';
import {
  getAnynetStaticProvider,
  getAppConfiguration,
  getNetwork,
  getSubgraphConfig,
} from '../appconstants';
import { Platform, Dimensions, Keyboard } from 'react-native';
import * as Device from 'expo-device';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';
import { LogCustomError } from '../helpers/AppLogger';
import { fetchTokenOwner } from '../helpers/GraphQLHelperSubgraph';
import { fetchTokenOwner as fetchTokenOwnerIndexer } from '../helpers/GraphQLHelper';
import { getUserJSONdata } from '../helpers/ipfs';

let userSubscriptionsURL;
let loot8POAPInterfaceID;

export function setAll(state: any, properties: any) {
  if (properties) {
    const props = Object.keys(properties);
    props.forEach(key => {
      state[key] = properties[key];
    });
  }
}

export const listTokensOwner = async (
  tokenAddress: string,
  chainId: number,
  account: string,
) => {
  try {
    if (getNetwork() != chainId) {
      try {
        const response = await fetchTokenOwnerIndexer(account, tokenAddress);
        return response.map(res => {
          return { tokenId: res.tokenId };
        });
      } catch (error) {
        LogToLoot8Console('error in fetchTokenOwner from Indexer', error);
      }
    }

    const subgraphConfig = await getSubgraphConfig();

    if (
      subgraphConfig &&
      subgraphConfig.modules &&
      subgraphConfig.modules.listTokenOwner
    ) {
      const transfersData = await fetchTokenOwner(account, tokenAddress);
      const ownedTokens = {}; // To hold owned tokens by tokenId

      transfersData?.forEach?.(transfer => {
        const { tokenId, from, to, collection } = transfer;
        const tokenKey = `${collection}_${chainId}_${tokenId}`;

        if (!ownedTokens[tokenKey]) {
          ownedTokens[tokenKey] = {
            tokenId,
            blockNumber: transfer.blockNumber,
            address: transfer.collection,
            timestamp: transfer.timestamp,
            owned: false,
          };
        }

        // Check token ownership
        if (to.toLowerCase() === account.toLowerCase()) {
          // User received this token
          ownedTokens[tokenKey].owned = true;
        } else if (from.toLowerCase() === account.toLowerCase()) {
          // User sent this token
          ownedTokens[tokenKey].owned = false;
        }

      });

      const result = Object.values(ownedTokens)
        .filter((token: any) => token.owned)
        .map(({ owned, ...token }) => token)
        .filter(x => x);

      return result || [];
    }
  } catch (error) {
    LogToLoot8Console('error in fetchTokenOwner', error);
  }

  return [];
};

export const convertAreaData = (area, radius) => {
  if (area != null && area.length == 2 && radius > 0) {
    let newArea = [[Number(area[0]), Number(area[1])], Number(radius)];
    return newArea;
  }
  return [[], 0];
};

export function isLocationAvailable(userLocation: any, area: any) {
  if (!area || (area && area[0].length == 0)) return true;
  if (userLocation != null) {
    let distanceKM = getLocationDistance(
      userLocation.latitude,
      userLocation.longitude,
      Number(area[0][0]),
      Number(area[0][1]),
      false,
    );
    let distance = distanceKM * 1000; //distance in metre
    // LogToLoot8Console("distance in metre", distance);
    if (distance <= area[1]) {
      return true;
    }
  }
  return false;
}

export const getAppVersionFromAppStore = async () => {
  try {
    let appLatestVersion = '';
    const response = await fetch(
      'http://itunes.apple.com/lookup?bundleId=com.loot8.loot8-app',
      { method: 'GET', headers: { 'Content-Type': 'application/text' } },
    );

    if (response.status === 200) {
      const appMetaData = await response.text();
      if (appMetaData) {
        let appDataJson = JSON.parse(appMetaData);
        if (
          appDataJson &&
          appDataJson.results &&
          appDataJson.results.length > 0
        ) {
          let resultData = appDataJson.results[0];

          if (resultData && resultData.version) {
            appLatestVersion = resultData.version;
          }
        }
      }
    }
    return appLatestVersion;
  } catch (err) {
    console.log('get data from apple store failed', err);
  }
};

export const getAppVersionFromPlayStore = async () => {
  try {
    let appLatestVersion = '';
    const response = await fetch(
      'https://play.google.com/store/apps/details?id=com.loot8.loot8_app&hl=en&gl=US',
      { method: 'GET', headers: { 'Content-Type': 'application/text' } },
    );

    if (response.status === 200) {
      const appMetaData = await response.text();
      if (appMetaData) {
        const matchStr = appMetaData.match(
          /Current Version.+?>([\d.-]+)<\/span>/,
        );
        if (matchStr && matchStr.length > 1) {
          appLatestVersion = matchStr[1].trim();
        }

        if (appLatestVersion === '') {
          const matchNewLayout = appMetaData.match(/\[\[\["([\d-.]+?)"\]\]/);
          if (matchNewLayout && matchNewLayout.length > 1) {
            appLatestVersion = matchNewLayout[1].trim();
          }
        }
      }
    }
    //console.log('latest version for Android', appLatestVersion);
    return appLatestVersion;
  } catch (err) {
    console.log('get data from play store failed', err);
  }
};

export function getScaledAppVersion(version: String) {
  if (version && version != '') {
    let versions = version.split('.');
    let major = versions.length > 0 ? parseInt(versions[0], 10) * 100000 : 0;
    let minor = versions.length > 1 ? parseInt(versions[1], 10) * 1000 : 0;
    let patch = versions.length > 2 ? parseInt(versions[2], 10) * 10 : 0;

    return major + minor + patch;
  } else {
    return 0;
  }
}

export const clearNotificationBadge = async () => {
  await Notifications.setBadgeCountAsync(0);
};

export const dismissNotification = async (tapResponse, friendWallet) => {
  try {
    //dismiss all the notifications of the friend whose notification was tapped by user.
    const totalPresentNotifications =
      await Notifications.getPresentedNotificationsAsync();
    //when user tap a notification, that notification is already removed from tray but need to consider it to set badge count properly
    const presentNotificationCount =
      tapResponse && tapResponse !== null
        ? totalPresentNotifications.length + 1
        : totalPresentNotifications.length;
    if (presentNotificationCount) {
      const friendAddress =
        tapResponse && tapResponse !== null
          ? tapResponse.notification?.request?.content?.data?.friendWallet?.toLowerCase()
          : friendWallet?.toLowerCase();
      let tappedSenderNotifications = [];
      if (friendAddress) {
        tappedSenderNotifications = totalPresentNotifications.filter(
          n =>
            n.request?.content?.data?.friendWallet?.toLowerCase() ===
            friendAddress,
        );
      }
      const tappedSenderNotificationsCount =
        tapResponse && tapResponse !== null
          ? tappedSenderNotifications.length + 1
          : tappedSenderNotifications.length;

      if (tappedSenderNotificationsCount > 0) {
        //dismiss notifications from tray
        tappedSenderNotifications.forEach(async n => {
          await Notifications.dismissNotificationAsync(n.request.identifier);
        });
        if (Platform.OS === 'ios') {
          const existingBadgeCount = await Notifications.getBadgeCountAsync();
          //when user tap a notification, that notification is already removed from tray but badge contains its count so need to add it into length
          const countToReduce = existingBadgeCount
            ? existingBadgeCount - tappedSenderNotificationsCount
            : 0;
          Notifications.setBadgeCountAsync(
            countToReduce > 0 ? countToReduce : 0,
          );
        }
      } else {
        Notifications.dismissAllNotificationsAsync();
        if (Platform.OS === 'ios') {
          Notifications.setBadgeCountAsync(0);
        }
      }
    } else {
      Notifications.dismissAllNotificationsAsync();
      if (Platform.OS === 'ios') {
        Notifications.setBadgeCountAsync(0);
      }
    }
  } catch (ex) {
    LogCustomError(
      'dismissNotification',
      ex.code || ex.name,
      ex.message,
      ex.stack,
      [
        { tag: 'tapResponse', value: tapResponse },
        { tag: 'friendWallet', value: friendWallet },
      ],
    );
  }
};

export const isSmartAppBannerPresent = () => {
  let isPresent = false;
  if (Platform.OS === 'web' && Device.isDevice && Device.osName === 'iOS') {
    const windowHeight = Dimensions.get('screen').height;
    const appHeight = Dimensions.get('window').height;
    if (windowHeight - appHeight > 185) {
      //When smart app banner is not present, usually the difference is around 180 in ios
      isPresent = true;
    }
  }
  return isPresent;
};

export const getWalletTokenDetails = async (
  walletTokenAddress: string,
  userAddress: string,
  includeBalance: boolean = false,
) => {
  let appConfig = await getAppConfiguration();
  let walletTokenDetails;
  if (appConfig && appConfig.walletTokens) {
    const chainId = getNetwork();
    walletTokenDetails = appConfig.walletTokens.find(
      (wt, index) => chainId == wt.chainId,
    );
    if (walletTokenDetails && includeBalance) {
      const provider = getAnynetStaticProvider(walletTokenDetails.chainId);
      const walletBalance = Loot8Token__factory.connect(
        walletTokenDetails.address,
        provider,
      );
      const bal =
        Number(await walletBalance.balanceOf(userAddress)) /
        Math.pow(10, walletTokenDetails.decimal);
      walletTokenDetails = {
        ...walletTokenDetails,
        walletBalance: bal.toFixed(2),
      };
    }
  }
  return walletTokenDetails;
};

export const getUserSubscribedPassports = async userAddress => {
  try {
    if (
      !userSubscriptionsURL ||
      userSubscriptionsURL === null ||
      userSubscriptionsURL === ''
    ) {
      let appConfig;
      try {
        appConfig = await getAppConfiguration();
      } catch (err) {
        LogToLoot8Console(
          'needLocationChangeTrigger: Error while reading app config',
        );
      }
      if (
        appConfig &&
        appConfig.collectionTradeAPI &&
        appConfig.collectionTradeAPI.URL
      ) {
        userSubscriptionsURL = appConfig.collectionTradeAPI.URL;
      }
    }

    let subscribedList = [];

    if (!userSubscriptionsURL) return subscribedList;

    const response = await fetch(
      userSubscriptionsURL + userAddress.toLowerCase(),
      { method: 'GET', headers: { 'Content-Type': 'application/json' } },
    );

    if (response.status === 200) {
      const responseData = await response.json();
      if (responseData && responseData.data && responseData.data.length > 0) {
        subscribedList = responseData.data;
      }
    }
    return subscribedList;
  } catch (err) {
    LogToLoot8Console(
      'getUserSubscribedPassports: Error while fetching subscribed list',
      err,
    );
  }
};

export const getLoot8POAPCollectionInterfaceID = async () => {
  try {
    if (
      !loot8POAPInterfaceID ||
      loot8POAPInterfaceID === null ||
      loot8POAPInterfaceID === ''
    ) {
      let appConfig;
      try {
        appConfig = await getAppConfiguration();
      } catch (err) {
        LogToLoot8Console(
          'getLoot8POAPCollectionInterfaceID: Error while reading app config',
        );
      }
      if (
        appConfig &&
        appConfig.Loot8POAPCollectionInterfaceID &&
        appConfig.Loot8POAPCollectionInterfaceID.interfaceID
      ) {
        loot8POAPInterfaceID =
          appConfig.Loot8POAPCollectionInterfaceID.interfaceID;
      }
    }
    return loot8POAPInterfaceID;
  } catch (err) {
    LogCustomError(
      'getLoot8POAPCollectionInterfaceID',
      err.code || err.name,
      err.message,
      err.stack,
      [{ tag: 'Loot8POAPCollectionInterfaceID', value: loot8POAPInterfaceID }],
    );
  }
};

export const hideKeyboard = () => {
  if (Platform.OS !== 'web' && Keyboard.isVisible()) {
    Keyboard.dismiss();
  }
};

export const batchifyRequests = async (
  items: Array<any>,
  batchSize: number,
  callback,
) => {
  const totalItems = items.length;
  let processedItems = 0;
  while (processedItems < totalItems) {
    const currentBatchSize = Math.min(batchSize, totalItems - processedItems);
    const currentBatch = items.slice(
      processedItems,
      processedItems + currentBatchSize,
    );
    await Promise.all(currentBatch.map(callback));

    processedItems += currentBatchSize;
  }
};

export const getBatchedUserJsonData = async (
  walletAddresses: Array<any>,
  batchSize: number,
  data: string,
) => {
  const totalItems = walletAddresses.length;
  let processedItems = 0;
  let batchedStatus = {};
  while (processedItems < totalItems) {
    const currentBatchSize = Math.min(batchSize, totalItems - processedItems);
    const currentBatch = walletAddresses.slice(
      processedItems,
      processedItems + currentBatchSize,
    );
    // const walletAddresses = currentBatch.flatMap((user: { wallet: string}) => user?.wallet.toLowerCase())
    const statuses = await getUserJSONdata(currentBatch);
    for (const key in statuses) {
      if (statuses.hasOwnProperty(key) && statuses[key][data]) {
        batchedStatus[key.toLowerCase()] = statuses[key][data];
      }
    }
    processedItems += currentBatchSize;
  }
  return batchedStatus;
};
